<template>
  <div>
    <!-- the login form popup -->
    <HeaderBar
      title="司机绑定微信"
      :showLeft="false"
    ></HeaderBar>
    <div class="register">
      <div class="tel">
        <div class="telPre">
          <!-- the pre of the phone number -->
          <div
            @click.stop.prevent="selectPre"
            v-if="countryCodeList"
          >
            {{ countryCodeList[defaultPre].TelephoneCode }}
          </div>
          <div
            class="select-icon"
            @click.stop.prevent="selectPre"
          ></div>
          <div class="">
            <input
              type="tel"
              name="telphone"
              id="telphone"
              v-model.trim="telphone"
              placeholder="输入手机号"
              @blur.prevent="inputLoseFocus"
            />
          </div>
        </div>
      </div>

      <!-- login btn -->
      <!-- :class="['loginBtn', { 'login-disabled': disabledSubmit }]" -->
      <div
        class="loginBtn"
        @click.prevent="sumberphone"
      >
        提交
      </div>
      <!-- dropdown select list -->
      <transition name="slide">
        <div
          class="pre-select"
          v-show="showSelect"
        >
          <ul>
            <li
              v-for="(c, index) in countryCodeList"
              :key="c.ID"
              :class="{ active: defaultPre == index }"
              @click="change(index)"
            >
              {{ c.TelephoneCode }}
            </li>
          </ul>
        </div>
      </transition>
    </div>

  </div>
</template>

<script>
import { Popup, Toast } from "vant";
import { mapState, mapActions, mapMutations } from "vuex";
import { getStore } from "../../lib/storage";
import { validateTel } from "@/lib/validate";
import { inputBlur } from "@/mixins/inputBlur";
import HeaderBar from "@/components/headerbar/headerbar";
import { UpdateOpenid } from "@/service/";
import { APPID, REDIRECT_URI_Login } from "@/config";
export default {
  name: "driver",
  mixins: [inputBlur],
  components: {
    HeaderBar: HeaderBar,
    [Popup.name]: Popup,
    [Toast.name]: Toast,
    // PhoneCode
  },
  // props: {
  //   showLogin: {
  //     type: Boolean,
  //     default: false
  //   }
  // },
  data() {
    return {
      //   show: true,
      telphone: "",
      code: "",
      showSelect: false,
      defaultPre: 0,
      // 验证码按钮初始化值
      codeText: "获取验证码",
      // 验证码key值
      codeKey: "1",
      // 验证码按钮 class
      codeBtn: "codeBtn",
      codeClick: false,
      phoneCodeText: this.codeText,
    };
  },
  computed: {
    ...mapState({
      // wxinfo: ({ userVal }) => userVal.wxinfo,
      countryCodeList: ({ globalVal }) => globalVal.countryCodeList,
    }),
    wxinfo() {
      return JSON.parse(getStore("wx"));
    },
  },
  methods: {
    ...mapMutations(["SET_USER_INFO"]),
    ...mapActions(["getUserInfo"]),
    getopen() {
      const appid = APPID;
      const redirect_uri = REDIRECT_URI_Login;
      let url = `https://api.weixin.qq.com/sns/oauth2/access_token?appid=${appid}&secret=${redirect_uri}&code=${getStore(
        "wx"
      )}&grant_type=authorization_code`;
    },
    selectPre() {
      this.showSelect = !this.showSelect;
    },
    change(index) {
      this.defaultPre = index;
      this.showSelect = false;
    },
    async sumberphone() {
      console.log("sumberphone");
      let _this = this;
      let pre = this.countryCodeList[this.defaultPre].TelephoneCode;
      let telMsg = validateTel(pre, this.telphone);
      console.log("telMsg:", telMsg);
      if (telMsg !== true) {
        Toast(telMsg);
        return;
      }
      let openid = this.wxinfo.openid;
      //   let openid = "fjdaskfjsd";
      console.log("tel:", pre + this.telphone);
      console.log("openid:", openid);
      let res = await UpdateOpenid(pre + this.telphone, openid);

      console.log("phoen_res:", res);
      if (res && res.code == 1) {
        Toast("绑定成功！");
        // setTimeout(() => {
        //   _this.$router.go(-1);
        // }, 3000);
      }
    },
  },
  mounted() {
    if (!this.countryCodeList) {
      this.getCountry();
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.radiusPop {
  border-radius: 20px !important;
}

.van {
  &-popup {
    position: absolute !important;
  }
  &-overlay {
    position: absolute !important;
  }
}

.register {
  .size(690px, 512px);
  margin: 0 auto;
  padding: 60px;
  margin-top: 60px;
  .bg(@white);
  border-radius: 20px !important;
  box-sizing: border-box;
  position: relative;
}
.loginBtn {
  .size(570px, 80px);
  margin: 0 auto;
  .bg(@brand-green);
  .fontSize(30px);
  .color(@white);
  .text-center;
  line-height: 80px;
  .border-radius(4px);
}
.tel {
  .fontSize(30px);
  border-bottom: 2px solid #eee;
  input {
    .fontSize(30px);
    line-height: 42px;
  }
}

.telPre {
  display: flex;
  flex: 1;
  align-items: center;
  // .size(72px, 92px);
  line-height: 92px;
}

.select-icon {
  .size(12px, 20px);
  margin-left: 10px;
  margin-right: 20px;
  background: url("../../assets/back@2x.png") no-repeat left center;
  background-size: 12px 20px;
}

.code {
  display: flex;
  flex: 1;
  height: 92px;
  align-items: center;
  justify-content: space-between;
}

.codeBtn {
  .size(160px, 50px);
  .color(@brand-green);
  background: rgba(75, 143, 91, 0.1);
  .text-center;
  line-height: 50px;
  .fontSize(24px);
  .border-radius(4px);
}

.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.1);
  .color(#999);
}

.protocol-tips {
  margin: 20px 0 60px;
  .fontSize(24px);
  line-height: 34px;
  .color(#999);
}
.pre-select {
  width: 200px;
  height: 128px;
  background: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 30px;
  top: 132px;
  .fontSize(30px);
  .color(#999);
  .text-center;
  overflow-y: auto;
  // -webkit-overflow-scrolling: touch;
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    height: 64px;
    border-bottom: 2px solid #eee;
    line-height: 64px;
  }
}

.slide-enter-active {
  animation: slide-in 0.2s;
}
.slide-leave-active {
  animation: slide-in 0.2s reverse;
}

@keyframes slide-in {
  0% {
    height: 0;
  }
  50% {
    height: 96px;
  }
  100% {
    height: 192px;
  }
}
.active {
  .bg(#eee);
}

.login-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
</style>
